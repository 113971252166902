<script setup lang="ts">
import {
  IsPageVisibleForLoggedInUsersDocument,
  LandingPageDocument,
} from '~/graphql/generated'

import Box from '~/components/dato/Box.vue'
import Hero from '~/components/dato/Hero.vue'
import Navbar from '~/components/dato/Navbar/Navbar.vue'
import IntercomButton from '~/components/intercom/button.vue'
import IncompleteSignupBanner from '~/components/nav/warnings/IncompleteSignupBanner.vue'
import PageFooter from '~/components/shared/footer/index.vue'

import { useDatoEvaluateBoxComponents } from '~/composables/useDatoEvaluateBoxComponents'
import { useGraphqlQuery } from '~/composables/useGraphqlQuery'

import { useLoginStore } from '~/stores/login'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import { getDatoPageHeadData } from '~/helpers/getDatoPageHeadData'

import type { CommonContentRecord } from '~/graphql/generated'

const route = useRoute()
const { locale } = useI18n()

const { queryDatoCms } = useGraphqlQuery()

const { IS_LOGGED_IN, IS_INCOMPLETE_USER } = storeToRefs(useUserStore())
const { isTransitioningToSignup: IS_MID_PAGE_TRANSITION } = storeToRefs(
  useMiscBandSignupStore(),
)
const { display: LOGIN_IS_DISPLAYED } = storeToRefs(useLoginStore())
const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { SETUP_EVENT_LISTENERS: SETUP_RESIZE_EVENT_LISTENERS } =
  useMiscResizeStore()

const shouldDisplayIncompleteSignupBanner = computed(() => {
  if (route.path.includes('signup/')) return false
  return IS_INCOMPLETE_USER.value && !IS_MID_PAGE_TRANSITION.value
})

definePageMeta({
  async middleware(to) {
    const { $localePath, $pinia } = useNuxtApp()
    const { IS_LOGGED_IN } = useUserStore($pinia)
    const { queryDatoCms } = useGraphqlQuery()

    const response = await queryDatoCms(IsPageVisibleForLoggedInUsersDocument, {
      slug: to.params.slug,
    }).catch(console.error)

    if (!response?.data.value?.landingPage)
      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

    const isAvailableForLoggedInUsers =
      response.data.value.landingPage.isPageVisibleForLoggedInUsers

    if (isAvailableForLoggedInUsers || !IS_LOGGED_IN) return

    return navigateTo($localePath('/'))
  },
  layout: false,
})

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

onMounted(() => {
  SETUP_RESIZE_EVENT_LISTENERS()
  checkForNextQuery()
})

const { data } = await useAsyncData(async () => {
  const response = await queryDatoCms(LandingPageDocument, {
    slug: route.params.slug || 'main-lp-groover-co',
    locale: locale.value,
  }).catch(console.error)

  if (!response?.data.value?.landingPage)
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

  return {
    landingPage: response.data.value.landingPage,
    commonContent: response.data.value.commonContent,
  }
})

if (data.value === null)
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

const { landingPage, commonContent } = data.value
useHead(() => getDatoPageHeadData(landingPage.seo, !route.params.slug))

const { boxRecordSections } = useDatoEvaluateBoxComponents(
  () => landingPage.sections,
)

/**
 * When there is a next query open the login and it will automaticaly redirct to the next url.
 */
function checkForNextQuery() {
  const {
    query: { next },
  } = route

  if (!next || IS_LOGGED_IN.value) return
  LOGIN_SET_DISPLAY(true)
}
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <div v-if="landingPage">
      <Navbar
        v-if="landingPage.navbar?.navbar?.navbar"
        v-bind="landingPage.navbar?.navbar?.navbar"
        :landing-page-available-locales="[...landingPage.slugLocales]"
      />
      <IncompleteSignupBanner
        v-if="shouldDisplayIncompleteSignupBanner"
        :key="route.fullPath"
      />
      <div
        v-if="landingPage.hero"
        class="tw-bg-fill tw-pb-2xl tw-pt-4xl md:tw-pb-3xl md:tw-pt-5xl lg:tw-pb-4xl lg:tw-pt-7xl"
      >
        <Hero v-bind="landingPage.hero" />
      </div>
      <div
        v-for="(box, index) in boxRecordSections"
        :id="`box-${index + 1}`"
        :key="box.id"
      >
        <Box
          v-bind="{
            ...box,
            commonContent: commonContent as CommonContentRecord,
          }"
        />
      </div>
      <PageFooter />
      <IntercomButton />
      <transition>
        <LazyAuthLogin
          v-if="LOGIN_IS_DISPLAYED"
          :model-value="LOGIN_IS_DISPLAYED"
          class="inLayoutIgnoreFontUpscale ignoreFontUpscale"
          @update:model-value="LOGIN_SET_DISPLAY"
        />
      </transition>
      <LazyBandSignupInit class="inLayoutIgnoreFontUpscale ignoreFontUpscale" />
    </div>
  </Html>
</template>

<style scoped lang="scss">
:deep(.mainParentContainer) {
  @apply tw-max-w-[1200px] tw-px-lg md:tw-px-xl min-[1280px]:tw-mx-auto min-[1280px]:tw-px-0;
}
</style>
